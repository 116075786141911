import axios from 'axios';

import { API_URL, IS_CLIENT } from '../constants';

class AxiosInstance {
  constructor() {
    this.axiosClient = axios.create({
      baseURL: API_URL,
    });
    this.accessToken = (IS_CLIENT && localStorage.getItem('accessToken')) || null;
    this.refreshToken = (IS_CLIENT && localStorage.getItem('refreshToken')) || null;
    if (this.accessToken) {
      this.axiosClient.defaults.headers.common = { Authorization: `Bearer ${this.accessToken}` };
    }

    this.axiosClient.interceptors.response.use(
      (response) => response,
      (error) => Promise.reject((error.response && error.response.data) || error)
    );
  }

  async setTokens(accessToken, refreshToken, rememberMe) {
    this.axiosClient.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    if (rememberMe && IS_CLIENT) {
      await localStorage.setItem('accessToken', accessToken);
      await localStorage.setItem('refreshToken', refreshToken);
    }
  }

  clearData() {
    this.accessToken = null;
    this.refreshToken = null;
    localStorage.clear();
  }

  get(url, token) {
    if (token) {
      this.axiosClient.defaults.headers.common = { Authorization: `Bearer ${token}` };
    }
    return this.axiosClient.get(url);
  }

  post(url, data) {
    return this.axiosClient.post(url, data);
  }
}

export default new AxiosInstance();
