import React from 'react';

const Button = ({ title, loading, fullWidth, disabled = false }) => (
  <div className="login-form_buttons">
    <button
      className={`login-form_buttons__${fullWidth ? 'reset' : 'signIn'} btn`}
      type="submit"
      disabled={loading || disabled}
    >
      {loading ? (
        <div className="loader-wrapper">
          <div className="loader" />
        </div>
      ) : (
        title
      )}
    </button>
  </div>
);

export default Button;
